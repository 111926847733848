<template>
  <div class="">
    <Intro msg="Login" />
    <Login />
  </div>
</template>


<script>
import Intro from "@/components/component/Intro.vue";
import Login from "@/components/auth/Login.vue";

export default {
  name: "Log-in",
  components: {
    Intro,
    Login,
  },
};
</script>