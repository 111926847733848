<template>
  <div class="my-16">
    <div class="container px-4 mx-auto">
      <div class="md:w-2/4 xl:w-1/4 mx-auto">
        <form action="#" @submit.prevent="submit" class="mb-8">
          <!-- Email -->
          <md-field class="border-b mb-8">
            <label class="block text-red mb-1" for="email">Email</label>
            <md-input
              type="email"
              class="w-full focus:outline-none"
              id="email"
              name="email"
              v-model="form.email"
              value
              required
            ></md-input>
          </md-field>

          <!-- Password -->
          <md-field class="border-b mb-16" md-has-password>
            <label class="block text-red mb-1" for="password">Password</label>
            <md-input
              type="password"
              class="w-full focus:outline-none"
              id="password"
              name="password"
              required
              v-model="form.password"
            ></md-input>
          </md-field>

          <!-- Submit -->
          <div class="my-10">
            <input
              class="
                bg-red
                text-md text-white
                uppercase
                border border-red
                px-4
                py-1
                hover:text-red hover:bg-white
                cursor-pointer
                transition-colors
                duration-500
                ease-in-out
              "
              type="submit"
              value="Login"
            />
          </div>
        </form>
        <!-- Error -->
        <div v-if="error" class="">
          <p class="text-red">
            {{ error }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";

// Store
import store from "@/store";

export default {
  name: "Login",

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          // console.log("Data: ", data);
          store.commit("SET_AUTH", true);
          this.$router.replace({ name: "ReadEvents" });
        })
        .catch((error) => {
          console.log("Error: ", error.message);
          this.error =
            "Non esiste alcun utente corrispondente a questo identificatore.";
        });
    },
  },
};
</script>


<style lang="scss" scoped>
</style>