<template>
  <div class="my-16">
    <div class="container px-4 mx-auto">
      <div class="md:w-2/4 xl:w-2/4 mx-auto">
        <h2 class="text-2xl text-red text-center mb-16">
          La tua prenotazione è andata a buon fine!
        </h2>
        <p class="text-center mb-16">
          Riceverai una email di conferma all’indirizzo che hai indicato, <br />
          puoi presentarti il giorno dell’evento all’accoglienza indicando il
          tuo nome.
        </p>
        <div class="text-center">
          <router-link
            to="/"
            class="
              bg-white
              text-md text-red
              uppercase
              border border-red
              px-4
              py-2
              hover:text-white hover:bg-red
              cursor-pointer
            "
            >Torna alla home</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "ThankYou",
  components: {},
};
</script>

<style scoped lang="scss">
</style>
